.video-slide{
    cursor: pointer;
}
.vid-slider{
    
    margin: 0 auto;
  
    
}

.img-container{
    margin-top: 2vh;
}

.sel-img{
    width: 80vh;
    margin-bottom: 2vh;
}


.video-player{
    height: 69vh;
}

.vid-head{
    font-size: 1.65rem;
    padding: 3% 0% 2% 0% ;
}

.to-previous{
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    left: 10px;
    font-size: 30px;
    color: #fff;
    z-index:  1;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 2vh;   
}

.to-next{
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    right: 10px;
    font-size: 30px;
    color: #fff;
    z-index:  1;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 2vh;   
}

.dotStyle{

    margin: 0 3px;
    padding-top: 0.8rem;
    cursor: pointer;
    font-size: 20px;
    color: #2E5339;
}

.dotStyle:hover{

    color: #0a120c;

}

    /* Media queries begin */

    /* Meida query for 1100 (...) */

    @media (max-width: 1100px) {


        .vid-head{
            font-size: 1.35rem;
            padding: 2% 0% 2% 0% ;
        }

        .video-player{
            
            max-height: 45vw;
        }

        .to-next{
            font-size: 1.3rem;
            padding: 0.7rem;
        }

        .to-previous{
            font-size: 1.3rem;
            padding: 0.7rem;
        }

        
    }

    /* Meida query for 500*/

    @media (max-width: 500px){

        .video-player{
            
            max-height: 48vw;
            margin-bottom: 1rem;
        }

        .vid-head{
            font-size: 1.15rem;
            padding: 3% 0% 3% 0% ;
            letter-spacing: 0.6px;
        }

        .to-next{
            top: 52%;
            right: 6px;
            font-size: 1.2rem;
            padding: 0.6rem;
        }

        .to-previous{
            left: 6px;
            top: 52%;
            font-size: 1.2rem;
            padding: 0.6rem;
        }

    }