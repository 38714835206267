.main-container {
  align-items: start;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 80px;
  position: fixed;
  z-index: 1001;
  width: 100vw;
  box-shadow: 0px 1px 7px #c2c2c2cc;
}


.logo {
  
  height: 4.5vh;
  width: 4.5vh;
  
}

.title {
  padding-top: 8px;
  align-self: center;
  margin: auto 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: #181818;
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin: auto 0;
}

.land-li{
  list-style-type: none;
  

}


.land-logo-section {
  display: flex;
  gap: 14px;
  margin: auto 0;
}

.land-nav-links {
  border-radius: 50px;
  padding: 0.4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: black;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;
}
.land-nav-links.active {
  color: white;
  background-color: #2e5339;
}

.land-nav-links.active:hover {
  background-color: #2e5339;
}
.land-nav-links:hover {
  background-color: #c2dcca;
  transition: all 0.2s ease-out;
}



.navigation-menu {
  margin: auto 0;
}

.resources-dropdown{
  position: absolute;
  background-color: white;
  margin-top: 1%;
  padding: 0.9rem 0rem 0rem 0rem;
}


.dropdown-item{
  text-decoration: none;
  color: black;
  font-size: 1rem;
  cursor: pointer;

  
  
}

.dropdown-item-li{
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 0.85rem 2rem 0.9rem 1.5rem;
  border-top: 1px solid #d6d6d6;
}

.dropdown-item-li:hover {

  color: white;
  background-color: #2e5339;
  transition: all 0.2s ease-out;
}







/* Meida query for 1150px (...) */

@media (max-width: 1150px) {

  .main-container{
    padding: 20px 30px;

  }

  .menu{
    gap: 2px;
  }
 
}


/* Meida query for 950px (...) */

@media (max-width: 950px ) {

  .land-nav-links{
    padding-left: 1rem;
    padding-right: 1rem;
  }

}


/* Meida query for 930px (...) */

@media (max-width: 930px) {

  /* Styles for the expanded navigation menu */
.navigation-menu {
  display: none;
}

.main-container{
  z-index: 3;
}
}


/* Meida query for 500px (...) */

@media (max-width: 500px) {

  .land-top-nav-items{

    height: 6.5%;
  }
}