
/* Teachers resources Header section css starts */

.teacher-header{
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    background-color: rgba(5, 5, 5, 0.7);
}


.teacher-header-image{
    position: absolute;
 width: 100%;
 height: 100%;
 object-fit: cover;
 z-index: -1;
}


.teacher-header-content{

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 23vh;
    color: white;
}

.teacher-header-heading{

    font-size: 3.1rem;
    letter-spacing: 3px;
    padding-bottom: 3vh;

}


.teacher-header-text{

    letter-spacing: 2px;
    font-size: 1.1rem;
}


/* Teachers resources Header  section css ends */


.teachers-faq-section{

    padding: 5.5% 7% 2.5% 7%;

}


.acc-img{
    max-width: 100%; /* Ensure image does not exceed its container */
    width: 80%; /* Make image fill its container */
    height: auto; /* Maintain aspect ratio */
    padding: 3rem 0rem 4rem 0rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}





    /* Media queries begin */
    @media (max-width: 1250px) {



    .teachers-faq-section{

        padding: 11% 10% 2% 10%;
      }
    }

    @media (max-width: 991px) {


        .teacher-header-heading{
            font-size: 2.6rem;
            
        }

        .teacher-header-content{
            padding-left: 5%;
            padding-right: 5%;
        }



    }



    @media (max-width: 500px) {

        
        .teacher-header-heading{
            font-size: 2.03rem;
            
        }

    }