.map-container{
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    
 }
 
 .mapboxgl-control-container{
     display: none;
 }

 .sidebar{
    margin-top: 5rem;
 }