
/* Header  section css starts */
.header-section {
           
    background: linear-gradient(
      270deg,
      rgba(37, 69, 56, 0.76) 0.18%,
      #25452f 99.8%
    );
    padding: 20vh 5vw 9vh 6vw;
  }

  .header {
    gap: 40px;
    display: flex;
  }
  .header-text {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
    padding-top: 3%;
  }

  .intro-phrase {
    color: white;
    align-self: stretch;
    font-size: 2.8rem;
    font-weight: 1000;
    letter-spacing: 1px;
   
  }

  .explore-text {
    color: white;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    align-self: stretch;
    margin-top: 1.7rem;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: start;
  }

  .view-missions {
    color: #2e5339;
    text-align: center;
    letter-spacing: 1px;
    justify-content: center;
    border-radius: 50px;
    background-color: white;
    margin-top: 3.5vh;
    margin-left: -2px;
    padding: 2.2% 6%;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    
  }

  .view-missions:hover{

    background-color: #c2dcca;
    color: black;
  }

  .header-image {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 48%;
    margin-left: 2rem;
    justify-content: center;
  }


  /* Header  section css ends */










  /* About  section css starts */
 
  .about-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 6vh 5vw 10vh 5vw;
  }

  .about-header {
    margin: 46px 17px 0 0;
    max-width: 50%;
  }

  .about-section-title {
    justify-content: center;
    color: rgba(0, 0, 0, 1);
    font-size:  2.5rem;
    font-weight: 1000;
    
  }

  .about-highlight {
    color: rgba(46, 83, 57, 1);
  }
  .about-content {
    gap: 5vw;
    display: flex;
  }

  .about-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 70%;
    margin-left: 0px;
  }

  .about-section-description {
    justify-content: center;
    color: #202020;
    text-align: start;
    margin-top: 3.5vh;
    font-size: 1rem;
    font-weight: 400;
    line-height: 33px;
  }

  .about-image-wrapper {
    aspect-ratio: 1.37;
    object-fit: contain;
    object-position: center;
    width: 95%;
    overflow: hidden;
    margin: auto 0;
    margin-top: -6vh;
  }

  .about-learn-more {
    color: #fff;
    white-space: nowrap;
    border-radius: 25px;
    background-color: #2e5339;
    align-self: start;
    justify-content: center;
    margin: 30px 0px 0px 0px;
    padding: 12px 25px;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1.8px;
    cursor: pointer;
    
  }

  .about-learn-more:hover{
    background-color: #203f2a;
    
  }

   /* About  section css ends */








 /* Featured Mission section css starts */

 .featured-missions-container {
   
    flex-direction: column;
    overflow: hidden;
    position: relative;
    display: flex;  
    justify-content: center;
  }

  .featured-missions-img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .mission-container {
    position: relative;
    background-color: rgba(15, 15, 15, 0.77);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 60px;
  }

  .mission-details {
    width: 100%;
    margin: 5rem 0 0rem;
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
  }


  .featured-card-column {
    display: flex;
    flex: 21%;
    
  }

  .mission-title {
    color: #fff;
    text-align: left;
    letter-spacing: 1px;
    margin: auto 0;
    font-size: 2.3rem;
    font-weight: 700;
    
  }

  .mission-info {
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: 35px 40px;
    align-items: center;
  }

  .mission-name {
    color: #000;
    text-align: center;
    align-self: center;
    margin-top: 0px;
    max-width: 434px;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;

  }

  .f-mission-text{
    align-self: center;
    text-align: start;
    margin-top: 1.2rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }



 /* Featured Mission section css ends */











 /* Teacher Resources section css starts */


 .teachers-resources-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    background: linear-gradient(
      90deg,
      #255339 0.11%,
      rgba(37, 83, 57, 0.79) 99.89%
    );
    padding: 11vh 5vw 10vh 5vw;
  }


  .teachers-resources-header {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 700;
    grid-column: span 2;
    width: 65%;
  }

  .teachers-resources-description {
    color: #fff;
    font-size: 1.15rem;
    line-height: 2.2rem;
    max-width: 100%;
    width: 96%;
    
  }


  .teachers-resources-button {
    color: #2e5339;
    border-radius: 50px;
    background-color: #fff;
    padding: 12px 20px;
    margin-left: 7vw;
    margin-top: -5vh;
    font-size: 1.27rem;
  font-weight: 700;
  cursor: pointer;
  
  }

  .teachers-resources-button:hover{

      background-color: #c2dcca;
      color: black;
    }
  

  /* Teacher Resources section css ends */











  



   .veteran-interviews-section {
    position: relative;
    min-height: 477px;
    padding: 6vh 4vw 6vh 4vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align to the right */
    text-align: right; /* Align text to the right */
  }


  .vet-inter-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  .vet-inter-content {
    text-align: left; /* Align text to the left within the container */
    color: white;
    max-width: 45vw;
    margin-top: 30px;
  }

  .vet-heading {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }


  .vet-description {
    font-size: 1.2rem;
    letter-spacing: 1px ;
    line-height: 2.1rem;
    text-align: start;
  }


    /* Veteran Interviews section css ends */










    /* Media queries begin */
    @media (max-width: 1250px) {

        .header-text {
            
            padding-top: 1%;
          }

          .intro-phrase {
            
           
            font-size: 2.4rem;
    
           
          }

          .explore-text {
           
            letter-spacing: 3px;
            margin-top: 1.5rem;
            font-size: 0.8rem;
            
          }

          .header-image{
            width: 45%;
          }

          .featured-text-column{
            margin-left: 2vw;
          }

          .mission-title{

            font-size: 2.2rem;
          }

          .mission-details{

          }

          

          .teachers-resources-button{
            margin-left: 1vw;
            
          }

    }





  @media (max-width: 991px) {
    .header-section {
      padding: 18vh 6vw 8vh 6vw;
    }

    .header {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }

      .header-text {
        margin: auto 0;
        align-items: center;
        width: 100%;
      }

      .intro-phrase {
        text-align: center;
        margin: auto;
        max-width: 100%;
        font-size: 2.1rem;
        line-height: 45px;
      }

      .explore-text {
        text-align: center;
        margin: auto;
        max-width: 95%;
        margin-top: 3vh;
        font-size: 0.8rem;
      }

      .view-missions {
        white-space: initial;
        
        padding: 0.6rem 1rem;
        margin-top: 1.6rem;
        margin-bottom: 2rem;
      }

      .header-image {
        width: 100%;
        margin: auto 0;
        max-width: 100%;
        align-items: center;
        padding-top: 2vh;
        
      }

      .h-img {
        aspect-ratio: 1.42;
        object-fit: contain;
        object-position: center;
        width: 50%;
        overflow: hidden;
        margin: auto 0;
      }
    

      .view-missions{
        font-size: 1.1rem;
      }

      
        .about-container {
            padding: 3vh 7vw 6vh;
        }
      
        .about-header {
          max-width: 100%;
          margin: 40px 10px 0 0;
        }
      
     
        .about-section-title {
          max-width: 100%;
          font-size: 1.7rem;
          line-height: 40px;
        }
      
    
        .about-content {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      
     
        .about-column {
          width: 100%;
        }
      
        .about-section-description {
          max-width: 100%;
          margin-top: 2vh;
          line-height: 25px;
          font-size: 0.9rem;
        }
      
     
        .about-image-wrapper {
          display: none;
        }
      
      
        .about-learn-more {
          white-space: initial;
          margin-top: 25px;
          padding: 10px 24px;
          font-size: 15px;
          
      }
    
      .mission-container {
        max-width: 100%;
        padding: 4vh 8vw;
      }
  
      .mission-details {
          
          gap: 3rem;
          margin: 2rem 0rem ;
          
          
        }
  
        .featured-text-column {
          width: 100%;
          margin-left: 0px;
        }
        .featured-card-column {
          
            
            line-height: normal;
            width: 100%;
            flex: 0 0 45%;
            
          }
  
          .mission-title {
              margin-top: 1rem;
              font-size: 1.7rem;
              text-align: center;
        
            }
  
            .mission-info {
              max-width: 100%;
              margin-top: 40px;
              padding: 3vh 3vw;
              min-height: 29rem;
            }
  
            .mission-name {
              font-size: 1.1rem;
            max-width: 100%;
          }
      
          .teachers-resources-container {
            padding: 10vh 6vw 10vh 6vw;
           display: block;
           text-align: center;
          }
      
          .teachers-resources-header {
              margin: auto;
              width: 100%;
              text-align: center;
              font-size: 1.8rem;
          }
      
          .teachers-resources-description {
              width: 95%;
              margin: auto;
              margin-top: 2vh;
              text-align: center;
          }
      
          .teachers-resources-button {
            
                font-size: 1.1rem;
              margin: auto;
              margin-top: 4vh;
              align-self: center;
            }

    .vet-heading {
      font-size: 1.8rem;
      line-height: 1.3;
      margin-top: 20px;
    }
    .vet-description{
        font-size: 1rem;
        line-height: 1.6rem;
        letter-spacing: 0.7px;
        text-align: center;
    }
    .vet-inter-content{
        margin-top: 0px;
        max-width: 100%;
        text-align: center;
    }
    .veteran-interviews-section {
        padding: 6vh 8vw 6vh 8vw;
      align-items: center;
      min-height: 50px;
      background-color: rgba(15, 15, 15, 0.72);
    }

  }
 

  @media (max-width: 768px) {

    .featured-card-column{
      
      max-width: 100%;

    }

    .mission-details{
      flex-direction: column;
      gap: 2rem;
    
    }

    .mission-info{

     min-height: fit-content;
     padding: 4vh 4vw;
    
    }

    .mission-container{
      padding-bottom: 4rem;
    }



  }


  @media (max-width: 500px) {

    .intro-phrase{
      font-size: 1.6rem;
      margin: auto;
      max-width: 100%;
      line-height: 40px;
    }
    .explore-text{
    
      
      font-size: 0.7rem;
      line-height: 22px;

    }

    .header-section{
      padding: 17vh 6vw 6vh 6vw;
    }

    .header-image{
      display: none;
    }

  }