
 /* About Header section css starts */
.about-h-section {
    display: flex;
    gap: 20px;
    padding-top: 5%;
  }
  
 
  .about-h-text {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  
 
  
  .about-h-text-section {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0rem 7rem 0rem 7rem;
  }
  

  .about-h-picture {
    display: flex;
    width: 50%;
    max-width: 100%;
    flex-direction: column;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  .about-h-title {
    color: #000;
    font-size: 3.6rem;
    font-weight: 900;
    text-align: start;
  }
  
  .about-h-subtitle {
    color: #2e5339;
    margin-top: 8px;
    font-size: 4rem;
    font-weight: 900
}
  
  .about-h-description {
    color: #000;
    text-align: start;
    margin-top: 1.2rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: 3px;
    
  }
  
  .about-h-img {
   
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
  }
  
   /* About Header section css ends */






/* Our Mission section css starts */


.our-mission-section{

 
}


.our-mission{

  padding: 5.5% 10% 5.5% 10%; 
  background-color: #F8F8F8;
 

}

.our-mission-heading, .project-desc-heading, .about-faq-heading{

  font-size: 1.9rem;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 2px;
}

.our-mission-dash, .our-mission-dash-2{
  font-size: 2.6rem;
  color: #2e5339;
  text-align: center;
  margin-top: -0.8%;

}

.our-mission-dash-2{

  display: none;

}

.our-mission-text, .project-desc-text{

  padding-top: 2%;
  text-align: start;
  font-size: 1.17rem;
  line-height: 2rem;

}



.a-project-desc{

  padding: 5.5% 10% 5.5% 10%; 
  display: flex;
  margin: 0 auto;

}


.project-desc-image-con{
  flex-grow: 1;
}


.project-desc-text-con{

  flex-grow: 1;
  padding-left: 6%;
 
  
}


.project-desc-heading{
text-align: left;
}


.project-desc-text{
font-size: 1.1rem;
padding-top: 3%;
line-height: 2rem;

}



/* Our Mission section css ends */










/* About CTA section css starts */



.about-cta-section{
  position: relative;
  width: 100%;
}

.about-cta-image{
  
      width: 100%;
      height: auto;
      display: block;

}


.about-cta-content{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(5, 5, 5, 0.75);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      padding-left: 12%;
      padding-right: 12%;
}

.about-cta-heading{

  font-size: 3.1rem;
  letter-spacing: 2.6px;
  line-height: 5rem;
  font-weight: bolder;

}

.about-cta-button{
  color: #2e5339;
  border-radius: 50px;
  background-color: #fff;
  padding: 12px 30px;
  font-size: 1.18rem;
  letter-spacing: 1.7px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 18%;
}

.about-cta-button:hover{

  background-color: #c2dcca;
  color: black;

}



/* About CTA section css ends */










/* About FAQ section css starts */


.about-faq-section{

  padding: 5.5% 7% 8% 7%;
  border: 1px solid #b6b6b6;
  border-top: 0px;


}

/* About FAQ section css ends */








    /* Media queries begin */

  @media (max-width: 1250px) {
  
.about-h-text-section{
    padding: 0rem 3rem 0rem 3rem;
}

    .about-h-title{

        font-size: 3rem;

    }

    .about-h-subtitle{

        font-size: 3.3rem;

    }

    .project-desc-text{
      line-height: 1.7rem;
    }

    .about-cta-content{

      padding-left: 5%;
      padding-right: 5%;
    }




}



  @media (max-width: 991px) {


    .about-h-title{
        text-align: center;
    }

    .about-h-subtitle{
        text-align: center;
    }

    .about-h-picture {
        
   display: none;
      
    }
  
 
    .about-h-text-section {
      max-width: 100%;
     padding: 0rem 4rem 0rem 4rem;
    }
  

    .about-h-text {
      width: 100%;
    }
  
    .about-h-section {
      flex-direction: column;
      padding: 10rem 0rem 3rem 0rem;
      gap: 0px;
    }


    .project-desc-image-con{
      display: none;
    }

    .project-desc-text-con{

      padding-left: 0%;
    }

    .project-desc-heading{

      text-align: center;
    }

    .our-mission, .a-project-desc {

      padding: 7% 10% 8% 10%;

    }

    .our-mission-dash, .our-mission-dash-2{
      margin-top: -2%;
    }

    .our-mission-dash-2{
      display: block;
    }
    .our-mission-text, .project-desc-text{
      line-height: 1.9rem;
    }

    .about-cta-section{

      height: 45vh;
    }

    .about-cta-image{
      height: 100%;
    }

    .about-cta-heading{
      font-size: 2.6rem;
      line-height: 3.8rem;
    }

    .about-faq-section{

      padding: 11% 10% 13% 10%;
    }


  }
  


  @media (max-width: 500px) {

.about-h-text-section{
    padding: 0rem 1.8rem 0rem 1.8rem;
}

.about-h-title{
  font-size: 2.2rem;
}

.about-h-subtitle{
    font-size: 2.3rem;
  }
  
  .about-h-description{
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  .our-mission-heading, .project-desc-heading{

    font-size: 1.7rem;
    line-height: 2.5rem;
  } 

  .our-mission-text, .project-desc-text{
    line-height: 1.8rem;
    padding-top: 4%;
    padding-bottom: 8%;
  }

  .about-cta-heading, .about-faq-heading{
    font-size: 2rem;
    line-height: 3rem;
  }

  .about-cta-section{
    height: 25rem;
    
  }

  .about-cta-button{
    
    
    padding: 11px 25px;
    font-size: 1rem;
    
  }

  .about-faq-section{

    padding: 15% 10% 18% 10%;
  }

  }

