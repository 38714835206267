
.nav-div{
   
    
    
}
.gregg-logo-tn{
    margin-top: 9%;
    min-height: 2rem;
    min-width: 2rem;
    height: 3vw;
    width: 3vw;
}
.land-topnav-logo{
    padding-left: 0.6vw;
    font-size: 1.1rem;
    color: #181818;
    cursor: pointer;
    font-weight: bolder;
    letter-spacing: 0px; 
    vertical-align: middle;
     
}

.top-nav-items{
    position: fixed;
    z-index: 4;
    width: 100%;
    background: white;
    height: 10%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3px;
    box-shadow: 0px 1px 7px #c2c2c2cc;

}
.logo-section{
    vertical-align: middle;
    height: 100%;
    display: flex;
    padding-left: 5.8%;
    padding-right: 10%;
    align-items:center;
    justify-content:center;

}

.topnav-logo{
   
    font-size: 1.6rem;
    color: #181818;
    cursor: pointer;
    
}

.fa-jet-fighter{
    
    margin-right: 0.3rem;
    font-size: 24px;
}

.topnav-menu{
    
    display: flex;
    list-style: none;
    text-align: center;
    width: fit-content;
    background: #d6eedd;
    padding: 1rem 0rem 1rem 0rem;
    border-radius: 50px;
    

}


.nav-links.active{
    color: white;
    background-color: #2E5339;
    
    }


    .nav-links{
        border-radius: 50px;
        padding: 1rem 2rem 1rem 2rem;
        color: #122317;
        text-decoration: none;
        
    }
    .nav-links.active:hover {
        background-color: #2E5339;
       
    }
    
    .nav-links:hover{
        background-color: #add5ba;
        transition: all 0.2s ease-out;
    }
    
.nav-links-timeline{
    border-radius: 50px;
    padding: 1rem 3rem 1rem 3rem;
   
    color: #122317;
    text-decoration: none;
}

.nav-links-timeline:hover{
    background-color: #add5ba;
    transition: all 0.1s ease-out;
}


.nav-links-timeline.active{
    background-color: #2E5339;
    color: white;

}



.nav-links-activities.active{
    color: white;
    background-color: #2E5339;
    
    }

.nav-links-activities{
    border-radius: 50px;
    padding: 1rem 2rem 1rem 2rem;
    color: #122317;
    text-decoration: none;
}




.nav-links-activities.active:hover {
    background-color: #2E5339;
   
}
.nav-links-activities:hover{
    background-color: #add5ba;
    transition: all 0.2s ease-out;
}




.navlink-container{
 
 width: 100%;
 height: 100%;
 
}




    /* Media queries begin */

    /* Meida query for 930 (...) */

    @media (max-width: 930px) {


        .logo-section{
            padding-right: 8%;
            padding-left: 6.5%;
        }

        .land-topnav-logo{
            font-size: 0.95rem;
        }

        .nav-links{
         padding:   0.7rem 1rem 0.7rem 1rem;
        }

        .nav-links-timeline{
            padding:   0.7rem 1.4rem 0.7rem 1.4rem;
        }

        .nav-links-activities{
            padding:   0.7rem 1.4rem 0.7rem 1.4rem;
           }
   
        .topnav-menu{
            padding: 0.7rem 0rem 0.7rem 0rem;
            width: fit-content;
        }

        .top-nav-items{
            height: 9%;
        }


    }


    
    /* Meida query for 768px (...) */

    @media (max-width: 768px) {


        .gregg-logo-tn{
            
            min-height: 1.7rem;
            min-width: 1.7rem;
            
        }

        .land-topnav-logo{
            font-size: 0.93rem;
                    }

        .logo-section{
            padding-right: 5%;
            padding-left: 2%;
        }

        .nav-links{
            font-size: 0.92rem;
            padding:   0.5rem 0.8rem 0.5rem 0.8rem;
           }
   
           .nav-links-timeline{
            font-size: 0.92rem;
               padding:   0.5rem 1.1rem 0.5rem 1.1rem;
           }
   
           .nav-links-activities{
            font-size: 0.92rem;
               padding:   0.5rem 1.1rem 0.5rem 1.1rem;
              }

              .topnav-menu{
                padding: 0.5rem 0rem 0.5rem 0rem;
                width: fit-content;
            }

              


    }


      /* Meida query for 600px (...) */

      @media (max-width: 600px) {

        .logo-section{
            display: none;
        }

        .top-nav-items{
            padding-left: 2.5%;
            height: 9%;
            
        }
      

        .nav-links{
            border-radius: 24px;
            font-size: 0.85rem;
            padding:   0.82rem 0.5rem 0.82rem 0.5rem;
           }
   
           .nav-links-timeline{
            border-radius: 24px;
            font-size: 0.85rem;
               padding:   0.72rem 0.6rem 0.72rem 0.6rem;
           }
   
           .nav-links-activities{
            border-radius: 24px;
            font-size: 0.85rem;
               padding:   0.72rem 0.7rem 0.72rem 0.7rem;
              }
    
              .topnav-menu{
                border-radius: 18px;
                padding: 0.6rem 0rem 0.7rem 0rem;
                
              }
    
    }