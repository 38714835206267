.c-role1{
 background-color: rgb(120, 201, 163);
 height: 100vh;   
 padding-top: 90px;
 text-align: center;
}

.c-role2{
    background-color: rgb(178, 138, 212);
    height: 100vh;   
    padding-top: 90px;
    text-align: center;
   }

   .c-role3{
    background-color: rgb(200, 214, 148);
    height: 100vh;   
    padding-top: 90px;
    text-align: center;
   }
   .c-role4{
    background-color: rgb(141, 196, 206);
    height: 100vh;   
    padding-top: 90px;
    text-align: center;
   }
   .c-role5{
    background-color: rgb(153, 127, 212);
    height: 100vh;   
    padding-top: 90px;
    text-align: center;
   }
   .c-role6{
    background-color: rgb(53, 53, 53);
    height: 100vh;   
    padding-top: 90px;
    text-align: center;
   }
   .cr-side-link{
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 550;
    letter-spacing: 0.1rem;
    padding: 2vh 0vh 2vh 3vh ;
}

.cr-side-link:hover{
background-color: #457e56;
}
.cr-side-link.active{
    background-color: #6FB784;
    }