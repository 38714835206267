

.time-card-image{
  width: 100%; /* Set the width to 100% */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Ensure the image covers the entire container */
  margin-bottom: 0.3rem;
}

.text-card-id {
  font-size: 0.83rem;
    font-weight: 100;
    color: rgb(160, 160, 160);
    line-height: 60%;
    text-align: center;
    text-justify: inter-word;
}

.time-section-block{
  height: 85vh;
  width: 100%;
  padding-top: 25vh;
}

.time-map-container{
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    
  }

  .time-flexbox-container{
    display: flex;
  }
  
  .time-flexbox-item{

  }

  .time-flexbox-item-1{

    background-color: #2E5339;
height: 89vh;
width: 40vw;
margin-top: 11vh;
position: fixed;
  }

  .time-flexbox-item-2{
    z-index: 2;    
    width: 156vw;
    margin-left: 40vw;
    margin-top: 5vh;

  }
  .mapboxgl-control-container{
     display: none;
  }
  
  .timeline-card-container{
    padding-top: 1%;
    padding-bottom: 3%;    
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .timeline-section{
 
  }
  
  .sidebar{
    margin-top: 5rem;
  }
  
  .time-card{
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.726);
    width: 30%;
    padding: 2% 2.2% 3% 2.2%;
    border-radius: 40px;
    margin: 8% 0% 5% 6%;
    z-index: 5;
    height: auto;
    max-height: 40rem;
  }
  
  .card::-webkit-scrollbar{
  
  width: 0.3rem;
  height: 8px;
  
  }
  .card::-webkit-scrollbar-track{
  
  background-color: rgba(136, 206, 136, 0);
  
  }
  .card::-webkit-scrollbar-thumb{
  background-clip: content-box;
  border-top-style: dashed;
  border-top: 2rem solid transparent;
  background-color: #2E5339;
  
  }
  
  
  .text-card-subtitle{
    font-size: 16px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 145%;
    text-align: start;
    text-justify: inter-word;
    padding-bottom: 3px;
  }

  .text-card-description{
    font-size: 0.83rem;
    font-weight: 100;
    color: rgb(0, 0, 0);
    line-height: 158%;
    text-align: start;
    text-justify: inter-word;

  }
  
  .heading-card{
    color: rgb(0, 0, 0);
    padding: 0% 0% 3% 0%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bolder;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
  } 

  .text-card-date{
    color: #2f4637;
    padding: 0% 0% 3% 0%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bolder;
    letter-spacing: 0.15rem;
    text-transform: uppercase;

  }
  .sec-t{
    padding: 6% 8% 10% 5%;
  }

  .sec-t:last-child{
    margin-bottom: 0px;
  }

  
.landing-navbar-t{
  position: fixed;
  top: 55%;
  right: 1.1%;
  transform: translateY(-50%);
  z-index: 1000;
  max-height: 73%;
  width: 16%;
  overflow-y: scroll;
  overflow-x: hidden;
 

}



.landing-navbar-t ul{
  list-style: none;
  padding:0; margin: 0;
  z-index: 1001;
 
}

.landing-navbar-t ul li{
  width: 15vw;
  position: relative;
  text-align: right;
}

.landing-navbar-t ul li .dot-t{
  z-index: 1000;
  color:#fff;
  display: block;
  padding: 6px 0;
}

.landing-navbar-t ul li .dot-t span{
  display: inline-block;
  font-size: 0.9rem;
  background: #2E5339;
  letter-spacing: 1px;
  padding:7px 15px;
  margin-right: 2px;
  border-radius: 3px;
  opacity: 0;
}

.landing-navbar-t ul li:hover .dot-t span{
  transform: translateX(-3vw);
  opacity: 1;
  
}

.landing-navbar-t ul li .dot-t span::before{
  content: '';
  position: absolute;
  top:50%; right:0;
  transform: translate(7px, -50%);
  border-left: 7px solid #2E5339;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  display: block;
}

.landing-navbar-t ul li .dot-t::before,
.landing-navbar-t ul li .dot-t::after{
  content: '';
  position: absolute;
  top:50%;
  border-radius: 50%;
  display: block;
  transition: .2s ease-out;
  
}

.landing-navbar-t ul li .dot-t::before{
  height:5px;
  width: 5px;
  border: 2px solid #ffffff;
  right:0;
  transform: translateY(-50%);
}

.landing-navbar-t ul li .dot-t.active::before,
.landing-navbar-t ul li:hover .dot-t::before{
  border-color: #ffffff;
  background: #ffffff;
}

.landing-navbar-t ul li .dot-t::after{
  height:15px;
  width: 15px;
  border: 2px solid #ffffff;
  right:-5px;
  transform: translateY(-50%) scale(0);
}

.landing-navbar-t ul li .dot-t.active::after,
.landing-navbar-t ul li:hover .dot-t::after{
  transform: translateY(-50%) scale(1);
}

.dots-background {
  background-color: rgba(0, 0, 0, 0.459);
  position: fixed;
  top: 15.6%;
  left: 96.5%;
  width: 2rem;
  max-height: 90%;
  z-index: 999;
  height: 45rem;
  border-radius: 50px;

  
}

.fa-arrow-down{
  position: fixed;
  right: 1.55%;
  background-color: #ffffff;
  z-index: 1000;
  top: 96%;
  border-radius: 5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-block: 0.2rem;

}





 /* Meida query for 1100  */

 @media (max-width: 1100px) {

  .time-card{
    width: 40%;
    min-width: 30vw;
    margin: 20% 0% 12% 4%;
  }

  .heading-card{
    font-size: 1.15rem;
  }

  .text-card-date{
    font-size: 1rem;

  }

  .text-card-description{
    font-size: 0.9rem;
    letter-spacing: 0.2px;
    line-height: 21px;
    
  }

}




 /* Meida query for 850 */

 @media (max-width: 850px) {

  .time-card{
    width: 40%;
    padding: 3% 2.5% 3% 2.5%;
    margin: 25% 0% 10% 6%;
    border-radius: 30px;
  }

  .heading-card{
    font-size: 1.1rem;
  }

  .text-card-date{
  font-size: 0.9rem;
}
  .text-card-subtitle{
    font-size: 0.89rem;
  }

  .text-card-description{
    font-size: 0.85rem;
    letter-spacing: 0.5px;
    line-height: 21px;
  }

  .landing-navbar-t ul li .dot-t span {
        
    display: none;
    
}

.landing-navbar-t ul li .dot-t  {
   
  padding-top: 2rem;
}

.landing-navbar-t ul li{
  width: 5vw;
 
  
}

.landing-navbar-t{
  width: 6%;
  top: 50%;
  max-height: 68%;
}
}


/* Meida query for 660 */

@media (max-width: 660px) {

  .time-card{
    width: 55%;
    padding: 3.5% 3% 3.5% 3%;
    margin: 42% 0% 10% 6%;
    border-radius: 30px;
  }

  .landing-navbar-t{
    width: 5%;
  
  }

}


    /* Meida query for 475  */

    @media (max-width: 475px) {

      .time-card{
        width: 70%;
        min-width: 50vw;
        padding: 5.5% 3.5% 5.5% 3.5%;
        margin: 70% 0% 10% 3%;
        background-color: #ffffffc5;
        border-radius: 30px;

      }

      .heading-card{
        font-size: 0.95rem;
        letter-spacing: 0.5px;
      }
      .text-card-date{
        font-size: 0.85rem;

      }

      .text-card-subtitle{
        font-size: 0.82rem;
      }
      .text-card-description{
        font-size: 0.81rem;
        letter-spacing: 0.1px;
        line-height: 19px;
      }

      .landing-navbar-t ul{

       
      }
      .landing-navbar-t ul li .dot-t span {
        
        display: none;
        font-size: 0.78rem;
        background: #2E5339;
        letter-spacing: 0.7px;
        padding: 7px 10px;
        margin-right: 0px;
        border-radius: 10px;
        opacity: 0;
        min-width: 25vw;
    }

    .landing-navbar-t ul li .dot-t  {
       
      padding-top: 2rem;
  }

    .landing-navbar-t ul li{
      width: 5vw;
      margin-right: 0rem;
      position: relative;
      text-align: right;
      
    }

  }

    




    /* MEDIA QUERIES FOR DOTS BACKGROUND */

     /* Meida query for 1440  */

 @media (max-width: 1450px) {


  .dots-background{
  
    background-color: rgba(0, 0, 0, 0.459);
    position: fixed;
    top: 15.6%;
    left: 96.5%;
    width: 2rem;
    max-height: 80%;
    z-index: 999;
    height: 35rem;
    border-radius: 50px;
   
  }

 }


 /* Meida query for 1280  */

 @media (max-width: 1280px) {

  .dots-background{

    left: 96.3%;

  }


 }


 /* Meida query for 1150  */

 @media (max-width: 1150px) {

  .dots-background{

    left: 96.1%;

  }


 }


 /* Meida query for 1020  */

 @media (max-width: 1020px) {

  .dots-background{

    left: 95.9%;

  }


 }

  /* Meida query for 940  */

  @media (max-width: 940px) {

    .dots-background{
  
      left: 95.6%;
  
    }
  
  
   }

   /* Meida query for 840  */

  @media (max-width: 840px) {

    .dots-background{
  
      left: 95.35%;
  
    }
  
  
   }


     /* Meida query for 770  */

  @media (max-width: 770px) {

    .dots-background{
  
      left: 95.1%;
      height: 35rem;
  
    }
  
  
   }


    /* Meida query for 670  */

  @media (max-width: 670px) {

    .dots-background{
  
      left: 92.7%;
  
    }

    .landing-navbar-t{
      right: 4%;
    }
  
  
   }


   /* Meida query for 580  */

  @media (max-width: 580px) {

    .dots-background{
  
      left: 92.2%;
      
  
    }
  
  
   }


     /* Meida query for 490  */

  @media (max-width: 490px) {

    .dots-background{
  
      left: 91.5%;
      
      
  
    }
  
  
   }


    /* Meida query for 425  */

  @media (max-width: 420px) {

    .dots-background{
  
      left: 90.7%;
      
  
    }
  
  
   }

    /* Meida query for 400  */

  @media (max-width: 400px) {

    .dots-background{
  
      left: 90.5%;
      
  
    }
  
  
   }

      /* Meida query for 360  */

  @media (max-width: 360px) {

    .dots-background{
  
      left: 89.8%;
      
  
    }
  
  
   }