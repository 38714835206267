.slider-container{
  width: 70vw;
  margin: 0 auto;
  margin-top: 3vh;
}

.photo-activity-container{

  text-align: center;
  
}

.sel-title{
  text-align: start;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0rem 0rem 0.5rem 0rem;
}



/* Meida query for 500 */

@media (max-width: 500px) {


  .slider-container{
    width: 100%;
    margin: 0 auto;
    margin-top: 0.3rem;
  }

  .sel-title{
    font-size: 1.1rem;
    font-weight: bold;
  }
}