.video-slider-container{
    width: 75vw;
    margin: 0 auto;
   
  }
  
  .video-activity-container{
   padding-top: 1vh;
    text-align: center;
  }


  

    /* Media queries begin */

    /* Meida query for 1100 (...) */

    @media (max-width: 1100px) {

      .video-slider-container{
      width: 80vw;
      }
    
  }


  /* Meida query for 500 */

  @media (max-width: 500px) {

    .video-slider-container{
    width: 85vw;
    padding-top: 0.7rem;
    }
  
}