* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

body{
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar{
  width: 0.4rem;

}

body::-webkit-scrollbar-track{
  background-color: #c2dcca;

}
body::-webkit-scrollbar-thumb{
  background-color: #2E5339;

}

html {
  scroll-behavior: smooth;
}

