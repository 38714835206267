.accordion{
    
    border: solid;
    border-width: 1px;
    border-color: #bcbcbcaa;
     box-shadow:  0px  0px  2px #d2d2d2; /* Apply the box shadow */    
     margin-top: 4rem;
     border-top: 0px;
     border-radius: 3px;

}

.accordion-sec:hover{
    background-color: #f3f3f3 !important;
}


.accordion-content-sec{

    background: #fff;
    padding: 3rem 5rem 3rem 5rem;
    text-align: justify;
    line-height: 1.6rem;
    
    
    
   

}

.accordion-head{
 
    padding: 2rem 3rem 2rem 5rem;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.8px;
    border-top: solid;
    border-width: 2px;
    border-color: #d6d6d6;

}

.plus-icon{
    width: 2.2rem;
    height: 2.2rem;
    background-size: 2.2rem;
    margin-top: -0.3rem;
    
}








    /* Media queries begin */

    @media (max-width: 991px) {

        .accordion-head, .accordion-content-sec{

            padding: 2rem 3rem 2rem 4rem;
        }


    }

    
    @media (max-width: 500px) {

        .accordion-head, .accordion-content-sec{

            padding: 2rem 2rem 2rem 2rem;
        }

        .accordion-head{
            font-size: 1.2rem;
        }


    }
  