.mc-sidebar{
    
}

.fa-house{
    
    color: white;
    font-size: 1.1rem;
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 9px;
    background-color: rgba(0, 0, 0, 0.212);
    border-radius: 60px;
}

.fa-house:hover{
    
    color: #2E5339;
    background-color: rgb(255, 255, 255);
}