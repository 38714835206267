.backstory-rest{
    margin-top: 0%;
width: 100%;

margin-left: -1%;
padding-bottom: 6%;
}
.backstory{
   
margin-top: 5%;
width: 100%;
height: 0px;
margin-left: -1%;
}
.mission-control-map{

    
    padding-top: 8%;
    padding-bottom: 3%;
    
    
    width: 100%;
    z-index: 2;
    position: relative;
}

.card{
    overflow-y: scroll;
    margin-top: 5rem;
    background-color: rgba(255, 255, 255, 0.849);
    height: auto;
    max-height: 70vh;
    width: 28%;
    margin: 5% 0% 8% 6%;
    padding: 2% 2% 3% 2%;
    border-radius: 40px;
}

.card::-webkit-scrollbar{

  width: 0.3rem;
  height: 8px;
  
}
.card::-webkit-scrollbar-track{
  
  background-color: rgba(136, 206, 136, 0);

}
.card::-webkit-scrollbar-thumb{
  background-clip: content-box;
  border-top-style: dashed;
  border-top: 2rem solid transparent;
  background-color: #2E5339;
 
}

  
.text-card{
    font-size: 0.9rem;
    font-weight: 100;
    color: rgb(0, 0, 0);
    line-height: 145%;
    text-align: start;
    text-justify: inter-word;
    padding-top: 0.3rem;
}

.heading-card-mc{
    color: rgb(0, 0, 0);
    padding: 0% 0% 3% 0%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.15rem;
}

.audio-section{
  text-align: center;
  font-size: 0.83rem;

}
.audio-player{
  max-height: 40px;
  max-width: 100%;
  margin-top: 0.2rem;
  text-align: left;
  width: 100%;
  
}


.audio-icon{
  color: white;
  font-size: 0.8rem;
  padding-block: 0.5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 0.1rem;
 margin-bottom: 0.5rem;
 background-color: rgb(11, 11, 11);
 border-radius: 10rem;
 cursor: pointer;
}

.audio-icon:hover{
  
  background-color: #183621;
  
}

    /* Media queries begin */

    /* Meida query for 1100  */

    @media (max-width: 1100px) {

      .card{
        width: 32vw;
        min-width: 30vw;
        margin: 10% 0% 12% 3%;
      }

      .heading-card-mc{
        font-size: 1.2rem;
      }

      .text-card{
        font-size: 0.9rem;
        letter-spacing: 0.2px;
        line-height: 21px;
      }

    }

    
    /* Meida query for 850 */

    @media (max-width: 850px) {

      .card{
        width: 40vw;
        padding: 3% 2.5% 3% 2.5%;
        margin: 15% 0% 15% 3%;
      }

      .heading-card-mc{
        font-size: 1.1rem;
      }

      .text-card{
        font-size: 0.85rem;
        letter-spacing: 0.5px;
        line-height: 21px;
      }
    }


    /* Meida query for 500  */

    @media (max-width: 500px) {

      .card{
        width: 65vw;
        min-width: 50vw;
        padding: 5% 3.2% 5% 3.2%;
        margin: 40% 0% 30% 3%;
        background-color: #ffffffc5;

      }

      .heading-card-mc{
        font-size: 1rem;
      }

      .text-card{
        font-size: 0.81rem;
        letter-spacing: 0.1px;
        line-height: 19px;
      }

    }