

.image-gallery{

    
    
    margin-top: 20px;
    margin: 0 auto;
    max-width: 100%;
    
}

.scrollchor-con{

    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap into multiple rows */
   justify-content: normal;    
    
    
    
}

.image-slide{
    flex: 0 0 auto;
    cursor: pointer;
    width: 20%;
    border-radius: 1px;
    padding: 5px;

}

.image-slide:hover {
    filter: brightness(80%);
  
}

.questions{
    
text-align: start;
width: 100%;
margin: 0 auto;
padding: 1rem 0rem 2rem 1rem;


}

.questions li{
    padding: 1vh 0vh 0vh 0vh;
    font-size: 1rem;
}
.img-slider{
    width: auto;
    align-content: center;
    display: flex;
    
  
}

.img-container{
    margin-top: 2vh;
}

.sel-img{
    width: 100%;
    margin-bottom: 2vh;
}


/* Meida query for 1024 */

@media (max-width: 1024px) {


    .image-slide{

        width: 25%;
    }


}

/* Meida query for 500 */

@media (max-width: 500px) {


    .image-slide{

        width: 33%;
    }

    .questions li{
        padding: 1vh 0vh 0vh 0vh;
        text-align: start;
        font-size: 1rem;
    }

    .questions{
    
       
        padding: 2rem 0rem 2rem 1rem;
        
        
        }

}