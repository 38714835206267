.content-section{
    z-index: 3;
    position: relative;
}

.fa-bars{
    /* position: absolute; 
    margin-top: -5.3vh; */
    padding: 0vh 3.5vh;
    font-size: 1.6rem;
    cursor: pointer;
}
.fa-gear{
    padding-right: 2vh;
}
.side-link{
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.15rem;
    font-weight: 500;
    letter-spacing: 0.11rem;
    padding: 2vh 0vh 2vh 2vh ;
}

.side-link:hover{
background-color: #457e56;
}
.side-link.active{
    background-color: #6FB784;
    }


.setting{
    height: 90vh;
    padding-top: 11vh;
background-color: #2E5339;
}



.conflict-cause{
    height: 90vh;
    padding-top: 11vh;

background-color: chartreuse;
}

.flexbox-container{ 
display: flex;

}


.flexbox-item-1{
    
background-color: #2E5339;
height: 89vh;
width: 30%;
max-width: 40vh;
min-width: 10vh;
margin-top: 11vh;
position: fixed;

}
.flexbox-item-2{
   
z-index: 2;    
margin-top: 0vh;
}
.comp-name{
    padding: 5vh 3vh 1.5vh 3vh;
    color: white;
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 0.15rem;
}

.hrline{
    margin: 0vh 2vh 2vh 2vh;
}

/* Individual item */
.bm-item {
    display: inline-block;
  
    color: #000000;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: #000000;
  }

  /* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 23px;
    height: 19px;
    left: 2%;
    top: 4%;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    border-radius: 50px;
    background: #273f2d;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: rgb(0, 146, 66);
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px;
    width: 30px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background-color: #2E5339;
    padding: 9% 0% 0% 0%;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  #page-wrap{
    margin-top: 0vh;
  }


  /* Media queries begin */


  
    /* Meida query for 930 (...) */

    @media (max-width: 930px) {


       /* Position and sizing of burger button */
      .bm-burger-button {
      
      width: 23px;
      height: 16px;
      left: 90%;
      top: 3.7%;
    }
    }



    /* Meida query for 768px (...) */

    @media (max-width: 768px) {


      /* Position and sizing of burger button */
     .bm-burger-button {
     
     width: 23px;
     height: 16px;
     left: 92%;
     top: 3.7%;
   }
   }


   @media (max-width: 600px) {


    /* Position and sizing of burger button */
   .bm-burger-button {
   
   width: 25px;
   height: 16px;
   left: 90%;
   top: 3.6%;
 }
 }