.land-side-link{
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    padding: 2vh 0vh 2vh 2vh ;
}


.land-side-link:hover{
    background-color: #457e56;
    }
.land-side-link.active{
        background-color: #6FB784;
        }