
/* Veteran Interviews Header section css starts */

.veteran-header{
    position: relative;
    width: 100%;
    height: 55vh;
    padding-top: 1vh;
    overflow: hidden;
    background-color: rgba(5, 5, 5, 0.7);
}


/* Veteran Interviews Header section css ends */








/* Veteran Interviews List section css starts */



.veteran-list-container{

    padding: 6.5% 8% 2% 8%;
    border: 1px solid #b6b6b6;
  
}



.veteran-list-title{

    font-size: 2.2rem;
    font-weight: bolder;
    text-align: center;
    letter-spacing: 2px;
}


.veteran-list-subtitle{
    padding-top: 1.5%;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 85%;
    margin: 0 auto;
}

.veteran-list{

    
    display: flex;
    justify-content: space-between;
    padding-top: 6%;
    flex-wrap: wrap;
    

}

.veteran-card{

    flex: 0 0 21%; /* Adjust the width of each card as needed */
    background-color: #C2DCCA;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 3% 2% 3% 2%;
    margin-bottom: 5%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: black;
    text-align: center;
    border-radius: 50px;

}

.vi-card-name{
    font-weight: bolder;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
}


.vi-card-dash{
    font-size: 2rem;
    margin-top: -1rem;
    padding-bottom: 4%;
    color: #6c8b75;
    
}


.vi-card-title{
    font-weight: bolder;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    padding-bottom: 6%;
    
}


.vi-card-location{
    font-weight: bold;
    font-size: 1.1rem;
    letter-spacing: 1.4px;
    padding-bottom: 15%;
}


.veteran-card-button{

    color: #fff;
    white-space: nowrap;
    border-radius: 25px;
    background-color: #2e5339;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    letter-spacing: 1.4px;
    cursor: pointer;
    text-decoration: none;
    align-items: end;
   
    
}



/* Veteran Interviews List section css ends */






    /* Media queries begin */
    @media (max-width: 1250px) {


        .veteran-card{
            padding: 2% 1.5% 2% 1.5%;
            border-radius: 20px;
        }
        .vi-card-name {
            font-weight: bolder;
            font-size: 1.4rem;
            letter-spacing: 1px;
         
        }

        .vi-card-title{
            font-size: 1.1rem;
            
        }

        .vi-card-location{
            font-size: 1rem;
        }

        .veteran-card-button{
            font-size: 0.9rem;
        }


    }


    

  @media (max-width: 991px) {

    .veteran-card {

        flex: 0 0 30%;
      padding:   4% 2% 4% 2%;
      

    }

    .veteran-list{
        
    }

    .vi-card-name{
        font-size: 1.2rem;
    }
    .vi-card-title{
        font-size: 1rem;
        
    }

    .vi-card-location{
        font-size: 0.9rem;
    }

  

    .veteran-list-subtitle{
        width: 100%;
        padding-top: 4%;
        padding-bottom: 2%;
        line-height: 1.7rem;
        font-size: 1.1rem;
    }

    .veteran-list-container{
        padding: 10% 8% 6% 8% ;
    }



  }


  @media (max-width: 500px) {


    .veteran-list-container{
        padding: 15% 8% 12% 8%;
    }

    .veteran-list{

        padding-top: 12%;
    }

    .veteran-card {

        flex: 0 0 45%;
      padding: 6% 2% 6% 2%;
        margin-bottom: 15%;

    }

    .veteran-list-title{
        font-size: 2rem;
    }

    .veteran-list-subtitle{
        font-size: 1.05rem;
        line-height: 1.5rem;
        
    }

    .vi-card-name{
        font-size: 1.1rem;
    }
    .vi-card-title{
        font-size: 0.9rem;
        
    }

    .vi-card-location{
        font-size: 0.8rem;
    }


  }