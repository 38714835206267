.activity-sec{

    
margin-bottom: 4.5rem;
margin-top: 3rem;
padding-top: 2.5rem;
padding-bottom: 1rem;
border-radius: 1rem;
background-color: #c2dcca;

}

.activity-back{
    background-color: #ffffff;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 3rem;
    
}

.activity-title-photo{

    padding-bottom: 1%;
    text-align: center;
}
.activity-title{
    text-align: center;
    margin-bottom: 1%;

}

.activity-content{
  
    width: 83%;
    margin: 0 auto;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.photo-desc{
    width: 70%;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0.6px;
    
}

.activity-content-photo{
   
}

.option-summary{
    text-align: center;
    padding-top: 9rem;
    padding-bottom: 2rem;
    width: 70%;
    margin: 0 auto;
}

.sum-para{
    padding: 3vh 0vh 0vh 0vh;
    font-size: 15px;
}

.sum-para-head{
    font-size: 23px;
    
}

.rec-card{
    border-radius: 25px;
    padding: 3.2vh;
    background-color: #c2dcca;
    text-align: start;
    margin: 5vh 0vh 0vh 0vh;
}

.activity-sec-alliances{
    padding-top: 25vh;
}

.outline-head{
    width: fit-content;
    border-radius: 10rem;
    padding: 0.3rem 0rem 0.3rem 0.1rem;
    font-size: 1.05rem;
    letter-spacing: 0.9px;
    background-color: #c2dcca;
    margin-bottom: 0.6rem;
    
    
}

.outline-text{
    text-align: start;
    letter-spacing: 0.6px;
    padding: 0.1rem 0rem 2.5rem 0.1rem ;
    font-size: 16px;
    line-height: 1.5rem;
    
}

.outline-big6{
    line-height: 1.5rem;
    text-align: start;
    letter-spacing: 0.6px;
    margin-bottom: 2rem;
    font-size: 16px;
    margin-top: -1rem;
    padding-left: 0.1rem;
    
}

.mission-text{
    text-align: center;
    letter-spacing: 0.6px;
    padding: 0rem 0rem 1.5rem 0.1rem ;
    font-size: 17px;
    line-height: 1.5rem;
    
}

.expand-btn{

    display: inline-block;
    background-color: #2E5339;
    border: none;
    color: white;
    padding: 0.6rem 0.9rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    letter-spacing: 0.6px;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 19px;
    
}
.expand-btn-expanded{
    background-color: rgb(143, 30, 32);
}


.expand-btn:hover{

    background-color: #4d705a;
}
.expand-btn-expanded:hover{
    background-color: rgb(159, 59, 61);
}

 
    /* Meida query for 850 */

    @media (max-width: 850px) {


        .option-summary{
         
            padding-top: 7.4rem;
        }

        .sum-para-head {
            font-size: 1.3rem;
        }
        
        
        .sum-para {
            font-size: 0.88rem;
            padding-top: 1rem;
            text-align: start;
        }
        
        .rec-card{
            font-size: 0.87rem;
            padding: 1rem 1rem 1rem 1rem;
            letter-spacing: 0.7px;
            margin-top: 1.9rem;
        }

        .activity-title{
            font-size: 1.3rem;
            letter-spacing: 0.8px;
        }

        .activity-title-photo{
            font-size: 1.3rem;
            letter-spacing: 0.8px;
            
        }

        .activity-content {
            font-size: 0.88rem;
            
        
        }

    }




    