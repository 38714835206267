.sel-mission-container{
  position: fixed;
  height: 8.5vh;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.979);
  width: 40%;
  margin-top: -1vh;
  


}

.sel-mission{

  
  padding-top: 2.5vh;
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: 0.3px;
  
}
.sec-f{
  padding-top: 5vh;
}
.land-texthead{

  font-size: 1.2rem;
  margin-top: 0.3rem;
}

.land-text{

  font-size: 0.9rem;
  text-align: start;
  margin-top: 0.7rem;
}

.activity-btn {
  margin-left: 5%;
}

.mission-btn, .activity-btn {
  display: inline-block;
  background-color: #2E5339;

  border: none;
  color: white;
  padding: 3% 5%;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.6px;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 19px;
}
.mission-btn:hover, .activity-btn:hover{
  background-color: #213d2a;
}
.land-flexbox-container{ 
  display: flex;
  
  }
  .land-flexbox-item-1{

    height: 100%;
    width: 60%;
    /* position: fixed; */
    
    }
 

.land-flexbox-item-2{
     
  width: 40%;
  padding-top: 9vh;
  height: 100vh;
  
  }
  
  .land-map{
    min-height: 100vh;
    width: 60%;
    position: fixed;
    
  }


.missions {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }


/* .sec{
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  color:#777;
  background: #f9f9f9;
  letter-spacing: 2px;
}

.sec:nth-child(odd){
  background: #ddd;
} */
.landing-navbar{
    position: fixed;
    top:50%;
    right: 2%;
    transform: translateY(-50%);
    z-index: 1000;
    max-height: 80%;
  }
 
  
  .landing-navbar ul{
    list-style: none;
    padding:0; margin: 0;
  }
  
  .landing-navbar ul li{
    width: 20vh;
    position: relative;
    text-align: right;
  }

  .landing-navbar ul li .dot{
    color:#fff;
    display: block;
    padding: 6px 0;
  }
  
  .landing-navbar ul li .dot span{
    display: inline-block;
    font-size: 0.9rem;
    background: #2E5339;
    letter-spacing: 1px;
    padding:7px 15px;
    margin-right: 2px;
    border-radius: 3px;
    opacity: 0;
  }
  
   .land-card{
    text-align: center;
    background-color: #c2dcca;
      width: 90%;
      margin-top: 11vh;
      
      padding: 5%;
      border-radius: 30px;
  }

  
  .landing-navbar ul li:hover .dot span{
    transform: translateX(-4vh);
    opacity: 1;
    
  }
  
  .landing-navbar ul li .dot span::before{
    content: '';
    position: absolute;
    top:50%; right:0;
    transform: translate(7px, -50%);
    border-left: 7px solid #2E5339;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    display: block;
  }
  
  .landing-navbar ul li .dot::before,
  .landing-navbar ul li .dot::after{
    content: '';
    position: absolute;
    top:50%;
    border-radius: 50%;
    display: block;
    transition: .2s ease-out;
    
  }
  
  .landing-navbar ul li .dot::before{
    height:5px;
    width: 5px;
    border: 2px solid #333;
    right:0;
    transform: translateY(-50%);
  }
  
  .landing-navbar ul li .dot.active::before,
  .landing-navbar ul li:hover .dot::before{
    border-color: #2E5339;
    background: #2E5339;
  }
  
  .landing-navbar ul li .dot::after{
    height:15px;
    width: 15px;
    border: 2px solid #2E5339;
    right:-5px;
    transform: translateY(-50%) scale(0);
  }
  
  .landing-navbar ul li .dot.active::after,
  .landing-navbar ul li:hover .dot::after{
    transform: translateY(-50%) scale(1);
  }
 .sel-mission-mobile-container{
  display: none;
 }

/* #map { position: absolute; top: 0; bottom: 0; width: 100%; } */

    /* #map {
        position: fixed;
        width: 50%;
    } */
    .features {
        /* width: 50%;
         margin-left: 50%; */
        
        font-family: sans-serif;
        overflow-y: scroll;
        background-color: #fafafa;
    }
    section {
        padding: 10% 8% 27% 13%;
      
      
        line-height: 180%;
        border-bottom: 1px solid #ddd;
        
        font-size: 13px;
    }
    section.active {
        opacity: 1;
    }
    section:last-child {
        border-bottom: none;
        margin-bottom: 50px;
    }


    .land-card-image{
      width: 100%; /* Set the width to 100% */
      height: auto; /* Maintain the aspect ratio */
      object-fit: cover; /* Ensure the image covers the entire container */
      border-radius: 15px;
      margin-top: 2.4vh;

    }

    .alert-mission{
      margin-block: 1rem;
      padding-block: 1.1rem;
      background-color: #2E5339;
      border-radius: 15px;
    }

    .coming-soon{ 
      text-align: center;
      color: rgb(255, 255, 255);
      letter-spacing: 3px;
      font-weight: 900;
      font-size: 1rem;

    }

    .alert-text-soon{
      padding-left: 1rem;
      padding-right: 1rem;
      letter-spacing: 0.6px;
      padding-top: 0.3rem;
      color: rgb(255, 255, 255);
      font-size: 0.85rem;
      text-align: center;
      line-height: 20px;
      

    }

    .h-image-container{
      position: relative;
      
    }

    .hover-text{
      position: absolute;
      top: 15%;
      left: 1rem;
      right: 1rem;
     
      color: white;
      padding: 10px;
      background-color: #070707a4;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0s ease-in;
    }

    .h-image-container:hover .hover-text {
      opacity: 1;
      visibility: visible;
    }

    .img-text{
      font-size: 0.7rem;
      text-align: start;
      line-height: 16px;
    }

    .img-source{
      font-size: 0.7rem;
      text-align: start;
      word-break: break-all;
      line-height: 16px;

    }

    .title-img-text{
      font-size: 0.79rem;
      text-align: start;
      letter-spacing: 1px;

    }





    /* Media queries begin */

    /* Meida query for 1100 (...) */

    @media (max-width: 1100px) {

      .land-card{
        text-align: left;
      }

      .land-texthead{
        text-align: center;
      }
      
      .mission-btn, .activity-btn {
 
  min-width: 60%;
  padding: 3.5% 5.5%;
  font-size: 0.9rem;
      }

      .activity-btn{
        margin-left: 0%;
      }

      .img-text, .img-source{

        font-size: 0.6rem;
        line-height: 14px;

      }
      .title-img-text{
        font-size: 0.6rem;
  
      }

    }

     /* Meida query for 900px (...) */

     @media (max-width: 900px) {
     
    
      .landing-navbar ul li:hover .dot span{
        
        opacity: 0;
        
      }
      
      .landing-navbar ul li {
       width: 4vw;
       
       
      }
    }




    /* Meida query for 768px (...) */

    @media (max-width: 768px) {

      .land-flexbox-item-1{
        width: 55%;        
        }
     
    
    .land-flexbox-item-2{
      width: 45%;

      }

      .land-map{
        width: 55%;
          }

      section {
      padding: 10% 6% 20% 10%;
      }

      .sel-mission{
        font-size: 1.6rem;
        padding-top: 2vh;
        text-align: center;
      
      }
      .sel-mission-container{
        width: 45%;
        height: 7vh;

      }

      .img-text, .img-source{

        font-size: 0.4rem;
        line-height: 10px;

      }
      .title-img-text{
        font-size: 0.5rem;
  
      }

    }

     /* Meida query for 500px (...) */

     @media (max-width: 500px) {

      .land-flexbox-container{

    
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    
      }
      .land-flexbox-item-1{
       
        width: 100%;
        }
     
    
    .land-flexbox-item-2{
      z-index: 2;
      min-width: 100%;
      min-height: 100%;
      background: transparent;
      

      }
      .land-map{
    width: 100%;
          }

          .missions-section{
            max-width: 70%;
           margin-top: 10%;
          }

          .landing-navbar{
            min-width: 1.8rem;
            background-color: rgba(0, 0, 0, 0.39);
            border-radius: 50px;
            top: 50%;
            padding: 2% 0% 2% 0%;
          }

          .landing-navbar ul {

            
            max-width: 1.8em;
           
            
            
          }

          .sel-mission{
           display: none;
            /* width: 80%;
            color: white;
            background: #2E5339;
            border-radius: 10px;
            font-weight: 100;
            padding: 1vh 1vh 1vh 1vh; */
          }
          .sel-mission-container{
            display: none;
          }

          section{
            padding: 75% 0% 0% 5%;
            border-bottom: 0px solid #ddd;
            
          }

          .sec-f{
            
            padding-top: 60%;
          }

          .land-card{
            
            background-color: #ffffffc5;
            width: 100%;
          }

          .land-texthead{
            margin-top: 0.1rem;
            font-size: 1rem;
          }

          .land-text{
            margin-top: 0.2rem;
            font-size: 0.81rem;
            line-height: 1.25rem;
          }

          .mission-btn, .activity-btn {
        
            
            padding: 0.4rem 0.6rem 0.4rem 0.6rem;
            text-align: center;
            font-size: 0.81rem;
            letter-spacing: 0.3px;
            margin-top: 0.5rem;
            min-width: 40%;
            border-radius: 14px;
          }

          .mission-btn{
            margin-right: 5%;

          }

          .landing-navbar ul li .dot::before{
            
            border: 2px solid #ffffff;
            
          }

          .landing-navbar ul li .dot.active::before,
  .landing-navbar ul li:hover .dot::before{
    border-color: #ffffff;
    background: #ffffff;
  }

  .landing-navbar ul li .dot::after{
    
    border: 2px solid #ffffff;
  }

  .landing-navbar ul li {
  width: 1.2rem;
  max-width: 1.8rem;
  }

  .landing-navbar ul li .dot{

    min-height: 3rem;
  }
  

  .landing-navbar ul li .dot span {
    display: inline-block;
    font-size: 0rem;
    background: #2E5339;
    border-radius: 3px;
    padding: 0px;
    
}

.sel-mission-mobile-container{
margin: auto;
width: 70vw;
padding-top: 4%;
}

.sel-mission-mobile{
  border-radius: 30px;
  background-color: #fffffff5;
  text-align: center;
  color: #070707;
  padding: 0.8rem 0.7rem 0.8rem 0.7rem;
  font-size: 1.2rem;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.sel-mission-mobile-arrow{
  
  text-align: center;
  color: #ffffffd2;
  padding: 0.1rem 0.7rem 0rem 0.7rem;
  font-size: 2rem;
  line-height: 21px;

}

.sel-mission-mobile-container{
  display: block;
}

.img-text, .img-source{

  font-size: 0.5rem;
  line-height: 12px;
}
.title-img-text{
  font-size: 0.6rem;

}

     }