.footer-section {
    align-self: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 6vh 16vw 6vh 6vw;
  }

  .footer-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: auto 0;
  }

  .footer-header-img {
    object-fit: contain;
    object-position: center;
    width: 40px;
    overflow: hidden;
    max-width: 100%;
  }

  .footer-header-description {
    color: #000;
    text-align: right;
    align-self: center;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font-weight: 700;
    font-size: 1.6rem;
    
  }

  .footer-nav-container {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8vw;
  }

  .footer-nav-items {
    align-self: start;
    display: flex;
    flex-direction: column;
  }

  .footer-nav-item {
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 0.8px;
    white-space: nowrap;
    line-height: 2.1rem;

  }

  .footer-nav-title{
    font-size: 1.1rem;
    line-height: 3.5rem;
    letter-spacing: 2.3px;
    font-weight: 600;
    color: black;

  }

  .footer-nav-link {
    color: black;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;

  }

  .footer-nav-link:hover{
    color: #698b74;
  }

  
  .copyright {
    color: #000;
    white-space: nowrap;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    padding: 3vh 1vh;
    font-size: 1rem;
  }


  .copyright-text{
     text-align: center;
  }

  
    /* Media queries begin */
    @media (max-width: 1250px) {


        .footer-section {

            padding: 7vh 6vw 7vh 6vw;
        
        }
        .footer-header {
            gap: 15px;
        }
        .footer-header-description {
            font-size: 1.4rem;
        }
    }


  @media (max-width: 991px) {

  .footer-nav-item {
    white-space: initial;
  }
  .footer-nav-container {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .footer-section {
        max-width: 100%;
        flex-wrap: wrap;
      }
      
 
    .copyright {
        white-space: initial;
        padding: 3vh 1vh;
      }
  
  
    }


    @media (max-width: 520px) {

        .footer-nav-container{

            display: block;
            width: 100%;

        }

        .footer-nav-items{
            padding-top: 1rem;
        }

        .footer-nav-item{
            text-align: center;
        }

        .footer-section{
          justify-content: center;
          gap: 0px;
        }

        .footer-header-description{
            text-align: left;
        }

        .footer-header{
            justify-content: center;
        }

    }