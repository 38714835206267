

.team-container{

    padding: 9rem 3rem 3rem 3rem;
    background-color: #ffffff;
    border: 1px solid #b6b6b6;
    border-top: 0px;


}

.team-heading{
    text-align: center;
    padding-bottom: 3%;
}

.team-title{

    font-size: 2.4rem;
    font-weight: 1000;
}

.team-subtitle{
    padding-top: 1%;
    color: #363636;
    font-size: 1.05rem;

}


.team-section{
    display: flex;
    justify-content: center;
    padding-top: 0%;
    flex-wrap: wrap;
}

.team-card{
    flex: 0 0 15%; /* Adjust the width of each card as needed */
    padding: 3% 2% 3% 2%;
    margin-bottom: 1%;
    color: black;
    text-align: start;
   
}

.member-img{

    margin-bottom: 1rem;
    border-radius: 10px;
   

}


.member-name{

    font-size: 1.2rem;

}

.member-role{
    font-size: 0.9rem;
    color: #727272;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
}

.linkden-logo{
    padding-bottom: 0.5rem;
    width: 20px;

}

.member-comment{

    font-size: 0.85rem;
    color: #5f5f5f;
}



  
    /* Media queries begin */



    @media (max-width: 1400px) {
    
        .member-img{

            width: 14rem;
        
        }
        
        }

    @media (max-width: 1165px) {
    
    .team-container{
        padding: 9rem 2rem 3rem 2rem;
    }

    .member-img{

        width: 12rem;
    
    }

    .member-comment{
        font-size: 0.8rem;
    }
    }


    @media (max-width: 991px) {

        .team-title{
            font-size: 1.8rem;
        }
        .member-img{

            width: 18rem;
        
        }

        .team-section{
            column-gap: 2rem;
        }
        .team-container{
            padding-top: 8rem;
        }
    }

    @media (max-width: 768px) {

    
        .member-img{
    
            width: 12.5rem;
        
        }

      
        }


    @media (max-width: 500px) {


        .team-container{

            background-color: white;
        }

        .team-section{
            row-gap: 1.5rem;
        }

        .member-img{
    
            width: 17rem;
        
        }

        .team-title{
            font-size: 1.5rem;
        }

        .team-subtitle{
            font-size: 0.9rem;
        }

        .team-heading{
            padding-bottom: 1.6rem;
        }

    }