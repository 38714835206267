.intro{
    
    background-color: white;
    padding-top: 8%;
    padding-bottom: 4%;
    width: 100%;

    }
    
    .title-text-1{
        text-align: center;
        font-size: 2.2rem;
        color: #36493c ;
        margin-top: 0%;
        padding: 2vh;
    }
    .title-text{
        text-align: center;
        font-size: 2rem;
        color: #36493c ;
        padding: 2.5% 0% 2% 0%;
        }
.p-text{
    color: #414742;
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
}

.p-text-con{
margin: auto;
width: 70%;
}


    /* Media queries begin */

    /* Meida query for 1100 (...) */

    @media (max-width: 1100px) {

            .intro{
                padding-top: 6rem;
            }

            .title-text-1{
                font-size: 1.7rem;
                letter-spacing: 1px;
            }

            .p-text{
                font-size: 0.95rem;
                width: 95%;
                margin: 0 auto;
            }

            

    }


    
    /* Meida query for 850 (...) */

    @media (max-width: 850px) {


        .title-text-1{
            font-size: 1.6rem;
            letter-spacing: 1px;
        }

        .p-text{
            font-size: 0.85rem;
            width: 100%;
            margin: 0 auto;
        }
    }

        /* Meida query for 500 (...) */

        @media (max-width: 500px) {

            
        }