

.contact-container{

    padding: 9rem 5rem 5rem 5rem;
    border: 1px solid #cccccc;
    background-color: #f5f5f5;

}

.contact-heading{
    text-align: center;
    padding-bottom: 4%;
}

.contact-title{

    font-size: 2.1rem;
    font-weight: bolder;
}

.contact-subtitle{
    padding-top: 1%;
    color: #363636;
    font-size: 1.05rem;

}


.contact-section{

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    padding: 4rem 3rem;
    border-radius: 10px;
}

.contact-left{

    flex: 0 0 35%;
    background-color: #2E5339;
    color: white;
    padding: 2.5rem 2.5rem;
    border-radius: 11px;

}


.contact-info{
    font-size: 1.6rem;
    letter-spacing: 1.1px;
    padding-bottom: 1.5rem;
}

.contact-info-subtitle{
    font-size: 1rem;
    letter-spacing: 1px;
    padding-bottom: 4rem;
}

.contact-info-span{
    padding-right: 2rem;
    vertical-align: middle;

}


.contact-info-text{
    padding-bottom: 2.5rem;



}



.contact-right{

    flex: 0 0 60%;
    padding-top: 2rem;
    

}

.contact-right-row{
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

}

.contact-col-1{

    flex: 0 0 48%;
}

.contact-col-2{

    flex: 0 0 48%;

}



.contact-button{
    float: right;
    margin-top: 3rem;
    background-color: #2E5339;
    color: white;
    font-size: 1rem;
    letter-spacing: 1px;
    border-radius: 25px;
    padding-block: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

label {
    display: block;
    margin-bottom: 8px;
    color: #333;
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    resize: none;
  }




  
    /* Media queries begin */
    @media (max-width: 1250px) {
    
    .contact-container{
        padding: 9rem 2rem 5rem 2rem;
    }

    
    }


    @media (max-width: 991px) {

        .contact-section{
            display: block;
            padding-bottom: 8rem;
            padding-top: 4rem;
        }

        .contact-button{
            margin-top: 2rem;
        }

        .contact-info-text{
            padding-bottom: 1.3rem;
        }

        .contact-info{
            padding-bottom: 1rem;
        }

        .contact-info-subtitle{
            padding-bottom: 3rem;
        }

        .contact-right{

            padding-top: 4rem;
        }
    }


    @media (max-width: 500px) {

        .contact-section{
            padding: 0%;
            padding-top: 2rem;
            padding-bottom: 4rem;
            
        }

        .contact-container{

            background-color: white;
        }


    }