.content-section-sa{
  width: 100%;
  max-width: 100%;
    margin: 0 auto;
}


.link-line{
text-decoration: none;

}
.option-btn {
    display: inline-block;
    background-color: #ffffff;
    border: none;
    color: #2E5339;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 0.4px;
    cursor: pointer;
    border-radius: 15px;
    
  }
  .option-btn:hover{
    background-color: #2E5339;
    color: #ffffff;
  }

.option-texthead{
    font-size: 1.2rem;
    margin-top: 0.3rem;
    color: #ffffff;
    letter-spacing: 1.4px;
    

}

.option-text{
    
  letter-spacing: 0.6px;  
  font-size: 0.95rem;
  text-align: start;
  padding: 2% 0% 6% 0%;
  color: white;
}
.option-card-container {
    display: grid;
  
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.3rem;
  width: 80%;
  max-width: 90%;
  padding-bottom: 4rem;
 
    
  }
  
  .option-card {
    text-align: start;
    background-color: #3D6A4A;
    margin-top: 1vh;
   padding: 1.5rem;
    border-radius: 20px;
   
    
  }


.ac-title-text-1{
    text-align: center;
    font-size: 1.8rem;
    color: #243a2b;
    margin-top: 0%;
    padding: 2vh;
}

.ac-p-text{

    color: #414742;
    text-align: center;
    width: 60vw;
    margin: auto;
}



.student-teacher-activities{
    padding: 10% 0% 0% 45%;
}

.intro-sa{
  background-color: white;
    padding-top: 7.5rem;
    padding-bottom: 2rem;
    width: 100%;
}



/* Meida query for 900 (...) */

@media (max-width: 900px) {

  .ac-title-text-1{
    font-size: 1.5rem;
  }

  .ac-p-text{
    width: 80vw;
    font-size: 0.9rem;
  }


  .option-card-container{

    width: 87%;
    gap: 1.5rem;
  }

  .option-card{

    padding: 0.9rem 0.9rem 1rem 0.9rem;

  }

  .option-texthead{
    font-size: 1.1rem;
    margin-top: 0rem;
  }

  .option-text{
    font-size: 0.9rem;
    padding-top: 0.6rem;
  }
}


/* Meida query for 768 (...) */

@media (max-width: 768px) {

  .option-card-container{

    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }


}

/* Meida query for 500 (...) */

@media (max-width: 500px) {


  .option-card{
    width: 75vw;
    margin: 0 auto;
    
  }
  .option-card-container{

    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }


  .option-texthead{
    text-align: start;
    font-size: 1.1rem;
    margin-top: 0rem;
    letter-spacing: 0px;
  }

  .option-text{
    text-align: start;
    font-size: 0.85rem;
    letter-spacing: 0.3px;
    padding-top: 0.6rem;
  }

  .option-btn{

    align-self: center;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem ;
  }

}